import {
  ChartPieIcon,
  PlusIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { type Campaign } from "@openqlabs/drm-db";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useCampaigns } from "~/providers/CampaignsProvider";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import Button from "../../base/Button";
import SidebarLink from "./SidebarLink";

export default function CampaignSidebar() {
  const [expanded, setExpanded] = useState(true);
  const { campaigns } = useCampaigns();
  const [sortedCampaigns, setSortedCampaigns] = useState<Campaign[]>([]);

  const campaignId = useRouter().query.campaignId;
  const isSidebarOpen = useIsSidebarOpen();
  useEffect(() => {
    const sortCampaigns = (campaignA: Campaign, campaignB: Campaign) => {
      return (
        campaignA.createdAt.getMilliseconds() -
        campaignB.createdAt.getMilliseconds()
      );
    };
    const sortedCampaigns = [...campaigns]?.sort(sortCampaigns);
    setSortedCampaigns(sortedCampaigns);
  }, [campaigns, setSortedCampaigns]);

  return (
    <div
      className={`flex flex-1 flex-col gap-5 overflow-y-auto py-5 ${
        isSidebarOpen ? "" : "items-center"
      } text-left`}
    >
      {isSidebarOpen ? (
        <div
          className={`flex rounded-md p-1 text-left text-muted hover:bg-gray-200/50`}
        >
          <Link className="flex flex-1 items-center gap-2" href={"/campaigns"}>
            <PresentationChartLineIcon className="sm-icon ml-2" />
            <span>Campaigns</span>
          </Link>
          <Button
            onClick={() => setExpanded(!expanded)}
            className="mr-1 h-8 bg-gray-200/50 text-gray-400 hover:bg-gray-300/50"
          >
            {expanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </Button>
          <Link href="/campaigns/new">
            <Button className="h-8">
              <PlusIcon className="h-4 w-4" />
            </Button>
          </Link>
        </div>
      ) : (
        <button onClick={() => setExpanded(!expanded)}>
          <PresentationChartLineIcon className="sm-icon" />
        </button>
      )}
      <div
        className={`flex grow flex-col overflow-y-auto ${
          isSidebarOpen ? "gap-1" : "gap-3"
        } ${expanded ? "block" : "hidden"}`}
      >
        {sortedCampaigns.map((campaign) => (
          <div className="w-full" key={campaign.id}>
            {isSidebarOpen ? (
              <SidebarLink
                isActive={campaign.id === campaignId}
                text={campaign.name}
                href={`/campaigns/${campaign.id}`}
              >
                <div>
                  <ChartPieIcon className="sm-icon" />
                </div>
              </SidebarLink>
            ) : (
              <Link
                href={`/campaigns/${campaign.id}`}
                as={`/campaigns/${campaign.id}`}
              >
                <div
                  className={`flex h-10 w-10 content-center items-center justify-center rounded-full border-2 bg-white leading-none text-offblack transition-all ${
                    campaign.id === campaignId
                      ? "border-gray-300"
                      : "border-white"
                  }`}
                >
                  {campaign.name.slice(0, 1)}
                </div>
              </Link>
            )}
          </div>
        ))}
        {!isSidebarOpen && (
          <div className="flex h-10 w-10 content-center items-center justify-center rounded-full bg-white leading-none">
            <Link href="/campaigns/new">
              <PlusIcon className="sm-icon" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
