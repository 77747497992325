import Link from "next/link";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import SidebarLinkExternal from "./SidebarLinkExternal";

export default function SidebarLink({
  children,
  href,
  className = "",
  text,
  isExternal = false,
  isActive = false,
}: Readonly<{
  children: React.ReactNode;
  href: string | { pathname: string; query: { overview: string } };
  className?: string;
  text: string;
  isExternal?: boolean;
  isActive?: boolean;
}>) {
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <Link
      className={`${className} flex w-full content-center items-center gap-3 rounded-lg px-3 py-2 text-muted hover:bg-gray-200/50 ${
        isSidebarOpen ? "justify-start" : "justify-center"
      } ${isActive ? "bg-gray-200/50 font-semibold text-black" : ""}`}
      href={href}
    >
      {children}
      {isSidebarOpen ? text : ""}
      {isExternal && <SidebarLinkExternal />}
    </Link>
  );
}
