import { ListBulletIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import SidebarLink from "./SidebarLink";

export default function DashboardLinks() {
  const isSidebarOpen = useIsSidebarOpen();
  const router = useRouter();

  return (
    <div
      className={`flex w-full flex-col gap-1 ${
        isSidebarOpen ? "items-start" : "px-3"
      } text-muted`}
    >
      <SidebarLink
        text="Overview"
        href={{ pathname: "/", query: { overview: "true" } }}
        isActive={router.asPath === "/"}
      >
        <ListBulletIcon className="sm-icon" />
      </SidebarLink>
    </div>
  );
}
