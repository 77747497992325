import { type ReactNode } from "react";
import { LayoutBreadcrumbs } from "./Layout";
import Profile from "./Profile";

export default function NavBar({
  children,
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return (
    <div className="sticky z-10 flex min-h-12 w-full content-center items-center justify-between px-8">
      <LayoutBreadcrumbs>{children}</LayoutBreadcrumbs>
      {/* <DarkModeSwitch />*/}
      <Profile />
    </div>
  );
}
