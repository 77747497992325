import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useRouter } from "next/router";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import DropDown from "../../base/DropDown";

export default function TeamSelector() {
  const { activeTeamAccount, teamAccounts, setActiveTeamAccount } =
    useTeamAccount();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const handleClick = () => {
    setOpen(!open);
  };
  const dropdownItems = teamAccounts.map((team) => {
    return {
      name: team.name,
      id: team.id,
    };
  });
  const handleSelect = (_name: string, id: string | undefined) => {
    const selectedTeamAccount = teamAccounts.find((team) => team.id === id);
    if (!selectedTeamAccount) return;
    setActiveTeamAccount(selectedTeamAccount);
    setOpen(false);
    router.push("/").catch((err) => console.error(err));
  };
  return (
    <div className="relative z-10 flex h-6 w-40 content-center items-center justify-center gap-4 py-1 text-left text-lg font-medium leading-tight">
      <ul
        className={`
        absolute left-0 top-0 flex w-min flex-col justify-center rounded-md bg-gray-100 dark:bg-gray-800
        `}
      >
        <div className="flex" key={activeTeamAccount.id + "initial"}>
          {activeTeamAccount.name}{" "}
          <button onClick={() => setOpen(!open)}>
            {open ? (
              <ChevronUpIcon className="relative top-0.5 mx-2 h-3 w-3" />
            ) : (
              <ChevronDownIcon className="top-.05 relative mx-2 h-3 w-3" />
            )}
          </button>
        </div>
        <DropDown
          className="top-6 bg-white w-fit overflow-y-auto max-h-[90vh]"
          filterOpen={open}
          items={dropdownItems}
          selectItem={handleSelect}
          closeDropDown={() => setOpen(false)}
        />
      </ul>
      <button onClick={handleClick}></button>
    </div>
  );
}
