import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  useIsSidebarOpen,
  useToggleSidebar,
} from "~/providers/SidebarProvider";

export default function ToggleSidebarButton() {
  const toggleSidebar = useToggleSidebar();
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <button
      onClick={() => toggleSidebar(!isSidebarOpen)}
      className="h-min rounded-md border-2 border-gray-300 p-1 text-gray-400 transition-colors hover:border-gray-400 hover:bg-gray-200 hover:text-gray-500"
    >
      {isSidebarOpen ? (
        <ChevronLeftIcon className="h-3 w-3" />
      ) : (
        <ChevronLeftIcon className="h-3 w-3 rotate-180 transform" />
      )}
    </button>
  );
}
