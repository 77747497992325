import {
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";
import SidebarLink from "./SidebarLink";

export default function BottomSidebar() {
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <div
      className={`flex flex-col gap-1 whitespace-nowrap border-t border-divider py-5 ${
        isSidebarOpen ? "" : "px-3"
      }`}
    >
      {/*<SidebarLink
        isExternal={false}
        text={"Integrations"}
        href="/integrations"
      >
        <FireIcon className="sm-icon" />
      </SidebarLink>*/}
      <SidebarLink isExternal={false} text={"Settings"} href="/settings">
        <Cog6ToothIcon className="sm-icon" />
      </SidebarLink>
      <SidebarLink
        isExternal={true}
        text={"Help"}
        href="https://openq.dev/help"
      >
        <QuestionMarkCircleIcon className="sm-icon" />
      </SidebarLink>
      <SidebarLink
        isExternal={true}
        text={"Back to openq.dev"}
        href="https://openq.dev"
      >
        <ArrowUturnLeftIcon className="sm-icon" />
      </SidebarLink>
    </div>
  );
}
