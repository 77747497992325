import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useIsSidebarOpen } from "~/providers/SidebarProvider";

export default function SidebarLinkExternal() {
  const isSidebarOpen = useIsSidebarOpen();
  return (
    <>
      {" "}
      {isSidebarOpen && (
        <div className="ml-auto p-1.5">
          <ArrowTopRightOnSquareIcon className="sm-icon group-hover:text-gray-500" />
        </div>
      )}
    </>
  );
}
