import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

export default function ChevronCorrectIcon({
  className = "",
  open = false,
}: Readonly<{
  className?: string;
  open?: boolean;
}>) {
  if (open) return <ChevronUpIcon className={className} />;
  else return <ChevronDownIcon className={className} />;
}
